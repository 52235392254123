// eslint-disable-next-line no-unused-vars
import { useState } from 'react';

const PresaleOTCSection = () => {

    return <>
            <p className='paragraph'>&nbsp;</p>

            <p className='txt'>
                Buy Over The Counter
            </p>
            <p className='paragraph'>
                Alternatively, we also accept other payment methods such as CashApp, Zelle, etc., for over-the-counter (OTC) transactions. Please contact us at <a href="mailto:contact@jetvoy.com">contact@jetvoy.com</a> for more information on how to proceed with these payment methods.
            </p>
    </>
}

export default PresaleOTCSection