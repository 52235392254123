import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import Home from "./pages/Home"
import MiniHome from "./pages/MiniHome"

const App = () => {
  return <>

    <div className="app">
      <Router>
        <Routes>
          <Route index element={<MiniHome />} />
        </Routes>
      </Router>
    </div>
  </>;
}

export default App;