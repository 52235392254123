import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MantineProvider } from "@mantine/core"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily: 'Nexa, sans-serif',
        colors: {
          brand: ['#e8eeff', '#c0ccf1', '#98aae5', '#6f87db', '#4865d0', '#304cb8', '#243b8f', '#192a66', '#0e193f', '#030819'],
        },
        primaryColor: 'brand',
        primaryShade: 8
      }}
    >
      <App />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals