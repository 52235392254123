// eslint-disable-next-line no-unused-vars
import { Button } from '@mantine/core';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png'

const PlainNavbar = () => {

    const [isOpen] = useState(false);

    return <>
        <div className="navbar h-[10vh]">
            <nav className="menu">
                <NavLink to='/' className="menu-logo">
                    <img src={logo} alt="" />
                </NavLink>                
            </nav>

            <div className={isOpen ? "sidebar sidebar-open" : "sidebar"}>
                <h2>JETVOY</h2>
                <ul className="side-menu-items">
                    <li className="menu-item"><NavLink to="/" className={({ isActive }) => isActive ? "active_item" : ""}>Home</NavLink></li>
                    <li className="menu-item"><NavLink to="#">About</NavLink></li>
                    <li className="menu-item"><NavLink to="#">Vision</NavLink></li>
                    <li className="menu-item"><NavLink to="#">Utility</NavLink></li>
                    {/*<li className="menu-item"><NavLink to="#">Roadmap</NavLink></li>*/}
                    <li className="menu-item"><NavLink to="#">Tokenomics</NavLink></li>
                </ul>
            </div>

        </div>
    </>
}

export default PlainNavbar