// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import { List } from '@mantine/core';
import PresaleOTCSection from './PresaleOTCSection';

const presaleContract = "0xA5598eF2986591B7CA4F973738CA4C3bccD431e6"; // mainnet

const PresaleSection = () => {

    const [copySuccess, setCopySuccess] = useState('');


    function copyToClipboard(e) {        
        //document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        //e.target.focus();
        navigator.clipboard.writeText(presaleContract);
        setCopySuccess('Copied!');
      };

    return <>
            <div className='section'>
                <div className='segment'>
                    <div id='about'>
                        <p className='head_txt'>Our presale is currently open</p>
                        <div className='container'>                            
                            <div className='right'>
                                <p className='txt'>
                                    Buy Presale Directly From Our Contract
                                </p>
                                <p className='paragraph'>
                                    We have made buying tokens from our presale as easy as possible!  Simply send BNB directly to this wallet address <b>{presaleContract}</b> <button className='btn' onClick={copyToClipboard}>{copySuccess ? "Copied!" : "Copy"}</button> and the tokens will be sent back to your account automatically.
                                </p>
                                <p className='paragraph'>
                                    Note: Please ensure tokens are sent from a wallet you control (Metamask, Rabby, Trustwallet, etc) and not from an exchange.
                                </p>
                                <p className='paragraph'>
                                    <h3>Details</h3>
                                    <List>
                                            <List.Item>Minimum Buy: 0.01 BNB</List.Item>
                                            <List.Item>Maximum Buy: 4 BNB</List.Item>
                                            <List.Item>Rate: 1 BNB = 100,000,000 JETVOYIOU</List.Item>
                                            <List.Item>Total Supply: 100,000,000,000</List.Item>
                                            <List.Item>Network: Binance Smart Chain</List.Item>
                                    </List>
                                </p>

                                <PresaleOTCSection />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
}

export default PresaleSection